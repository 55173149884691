// Font - DM Sans Variable
@import "@fontsource-variable/dm-sans/opsz.css";
@import "assets/icons.scss";

:root,
.p-component {
	font-family: "DM Sans Variable", sans-serif;
	--font-family: "DM Sans Variable", sans-serif;
}

// * {
//     border: 1px solid red;
// }

@layer tailwind-base, primeng, tailwind-utilities;

@layer tailwind-base {
	@tailwind base;
}

@layer tailwind-utilities {
	@tailwind components;
	@tailwind utilities;
}

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "assets/prime-style.scss";

// AG-Grid
@import "ag-grid-community/styles/ag-grid.min.css";
@import "ag-grid-community/styles/ag-theme-quartz.min.css";

.ag-theme-quartz {
	--ag-font-family: "DM Sans Variable", sans-serif;
}

// Hide file selector button
::file-selector-button {
	display: none;
}

.ag-root-wrapper {
	border-radius: 0px !important;
	border: 0px solid gray !important;
}

*::-webkit-scrollbar-track {
	border-radius: 0px;
	background-color: transparent;
}

*::-webkit-scrollbar {
	width: 10px;
	height: 10px;
	background-color: #f5f5f5;
}
.file_uploadform::-webkit-scrollbar {
	width: 5px;
	height: 5px;
	background-color: #f5f5f5;
}
*::-webkit-scrollbar-thumb {
	background-color: transparent;
	position: absolute;
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(217, 217, 217, 1);
	background-color: #afafaf;
}

.ag-theme-quartz {
	--ag-icon-font-color: rgba(27, 28, 31, 1);
	--ag-borders-critical: solid 1px;
	--ag-border-color: transparent;
	--ag-header-column-resize-handle-color: rgba(217, 217, 217, 1) !important;
	--ag-header-column-resize-handle-height: 0 !important;
	--ag-header-column-resize-handle-height: 100% !important;
	--ag-header-column-resize-handle-width: 1px !important;
	--ag-checkbox-checked-color: #383e3b !important;
	--ag-checkbox-unchecked-color: #afafaf !important;

	.ag-horizontal-right-spacer.ag-scroller-corner {
		width: 0px;
		min-height: 0px;
	}

	.ag-ltr .ag-header-select-all {
		margin-right: 10px !important;
		display: none;
	}

	.ag-cell-label-container {
		justify-content: space-between;
		align-items: baseline;
		height: 100%;
		width: 100%;
		padding: 11px 0;
	}

	.ag-header-cell-label {
		align-items: baseline;
	}

	.ag-row {
		border: none;
		border-bottom: 1px solid rgba(243, 243, 243, 1);
		z-index: 0;
		&.ag-row-focus {
			z-index: 1;
		}
	}

	.priority-center-icon {
		overflow: unset;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.ag-tabs {
		.ag-icon {
			color: black;
		}
	}
	.ag-header-cell {
		.ag-icon-filter,
		.ag-icon-menu-alt {
			display: none;
		}
		&:hover {
			.ag-icon-filter,
			.ag-icon-menu-alt {
				display: block;
			}
		}
	}
	.ag-header-cell.rightheader {
		border-right: 1px solid var(--ag-header-column-resize-handle-color);

		.ag-header-icon {
			display: none;
		}
	}

	.ag-row {
		.ag-cell-value.rightheaderlastcol {
			// background-color: rgba(242, 243, 244, 1);
			text-align: right !important;
			color: rgba(27, 28, 31, 1) !important;
			font-weight: 700 !important;
			font-size: 12px;
			border-right: 0px solid rgba(242, 243, 244, 1) !important;
		}
	}

	.ag-row-selected::before {
		background-color: #fbfbfb !important;
		border-bottom: 1px solid #f3f3f3 !important;
	}

	.ag-row-hover {
		.ag-cell-value.rightheaderlastcol {
			// background-color: rgba(242, 243, 244, 1);
		}
	}

	.ag-header-group-cell-label.ag-sticky-label {
		width: 100%;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border-bottom: 0px solid transparent !important;
	}

	.ag-header {
		--ag-header-background-color: rgba(242, 243, 244, 1);
		--ag-header-foreground-color: rgba(27, 28, 31, 1) !important;
		font-size: 14px;
		line-height: 16px;
		font-weight: 500;
		letter-spacing: -0.5%;

		.ag-header-cell.rightheader .ag-header-cell-label {
			justify-content: right;
			color: rgba(27, 28, 31, 1);
		}
	}

	.ag-header-row {
		font-weight: 600;
	}

	.ag-pinned-left-header {
		background-color: rgba(242, 243, 244, 1);
		--ag-header-foreground-color: rgba(27, 28, 31, 1);
	}

	.ag-cell {
		font-size: 13px;
		font-weight: 400;
		text-align: right;
		color: rgba(64, 64, 64, 1);
		padding-top: 4px;
	}

	.lastscore {
		border-right: 1px solid rgba(217, 217, 217, 1);
	}

	.ag-pinned-left-cols-container {
		.ag-row {
			background-color: rgba(255, 255, 255, 1) !important;
		}

		.ag-cell {
			font-size: 13px;
			font-weight: 400;
			color: rgba(64, 64, 64, 1);
			border-right: 1px solid transparent !important;
			text-align: left;

			&.hyperlink-add {
				color: rgba(64, 64, 64, 1) !important;
				cursor: pointer;
			}
		}

		.ag-cell:last-child {
			border-right: 0px solid rgba(248, 248, 248, 1) !important;
		}

		.ag-cell-focus:not(.ag-cell-range-selected):focus-within {
			border-right: 1px solid var(--ag-range-selection-border-color) !important;
		}
	}

	.ag-pinned-left-header {
		.ag-icon {
			color: black;
		}

		.ag-header-cell.product-Header {
			align-items: baseline;
		}
	}
	&.checkbox {
		.ag-selection-checkbox {
			display: none;
		}
		// .ag-row-hover .ag-selection-checkbox {
		// 	visibility: visible;
		// }
		// .ag-row-selected .ag-selection-checkbox {
		// 	visibility: visible;
		// }
	}
}

.ag-header-row:not(:first-child) .ag-header-cell:not(.ag-header-span-height.ag-header-span-total),
.ag-header-row:not(:first-child) .ag-header-group-cell.ag-header-group-cell-with-group {
	border-top: 0px solid #dddddd !important;
	height: 56px !important;

	.ag-header-cell-resize {
		z-index: 2;
		width: 8px;
		top: auto !important;
		bottom: 0 !important;
	}
}

.ag-pinned-left-header {
	.ag-header-row.ag-header-row-column-group {
		height: 100px !important;
	}
}

.ag-header-cell.ag-header-span-height .ag-header-cell-resize::after {
	height: 100% !important;
	top: 0px !important;
	width: 1px;
	background-color: rgba(217, 217, 217, 1);
}

.ag-floating-top {
	border-bottom: 0px solid rgba(243, 243, 243, 1) !important;
}
.ag-header-cell.ag-header-cell-sortable.ag-header-cell-auto-height {
	align-items: baseline;
}
.ag-theme-quartz .ag-row-pinned.ag-row-position-absolute.ag-row-first {
	z-index: 2;
	box-shadow: 4px -4px 5px 5px rgb(202, 209, 210);
	background-color: rgba(130, 180, 238, 0.04);
	border-bottom: 2px solid rgba(202, 209, 210, 1);

	.ag-cell {
		&.hyperlink-add {
			cursor: pointer;
			position: relative;
			padding-left: 35px;
			text-align: left;

			&::before {
				content: " ";
				position: absolute;
				left: 0px;
				background-image: url("assets/icons/label_important.svg");
				background-position: center center;
				background-repeat: no-repeat;
				width: 40px;
				height: 40px;
				background-size: 20px;
			}
		}
	}
}

.ag-floating-top {
	border-bottom: 1px solid rgba(202, 209, 210, 1);
	margin-top: 8px;
	overflow-y: hidden !important;
}

.ag-header-cell-comp-wrapper {
	.ag-header-cell-text {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis;
		overflow: hidden;
		line-height: 18px;
		max-height: 36px;
		white-space: break-spaces;
	}
}

.ag-header-cell.ag-header-cell-sortable.textCenter.ag-focus-managed {
	.ag-header-cell-comp-wrapper {
		.ag-header-cell-text {
			display: flow;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			line-height: 18px;
			max-height: 36px;
			max-width: 120px;
		}
	}
}
.ag-pinned-left-floating-top {
	.ag-cell {
		text-align: left;
	}
}

.coeff-table-grid,
.confirm-view-table {
	width: 100%;
	background-color: rgba(255, 255, 255, 1);
	border: 1px solid rgba(217, 217, 217, 1);
	border-top: 0px solid rgba(217, 217, 217, 1);
	border-bottom: 0px solid rgba(217, 217, 217, 1);

	&.import-view-grid {
		border: 0px solid rgba(217, 217, 217, 1);
	}

	&.product-detail-grid {
		border-top: 1px solid rgba(217, 217, 217, 1);
		border-radius: 4px 4px;
	}

	.ag-root-wrapper {
		border-radius: 0px;
		border: 0px solid rgba(217, 217, 217, 1);
	}

	.ag-header-cell-comp-wrapper {
		.ag-header-cell-text {
			display: flow;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			overflow: hidden;
			line-height: 18px;
			max-height: 36px;
			white-space: nowrap;
		}
	}

	.ag-theme-quartz {
		.ag-cell {
			padding-top: 0px;
			text-align: left;

			&.number-align-right {
				text-align: right;
			}

			&.more-icon {
				align-items: center;
				display: flex;
				justify-content: center;
			}
		}

		.ag-header-cell-label,
		.ag-cell-label-container {
			align-items: center;
		}
	}

	&.risk-table {
		.ag-root-wrapper {
			.ag-row {
				border-bottom: 0px solid rgba(243, 243, 243, 1);

				&.ag-row-selected {
					border: none;
					background-color: transparent;

					&::before {
						background-color: transparent;
					}
				}
			}

			.ag-cell {
				padding-top: 12px;

				&.ag-cell-focus:not(.ag-cell-range-selected):focus-within {
					border: 0px solid !important;
				}
			}
		}
	}

	&.logs-grid {
		.ag-theme-quartz {
			.ag-cell {
				padding-top: 3px;
			}
		}
		.ag-header-cell {
			.ag-icon-filter,
			.ag-icon-menu-alt {
				display: none;
			}
			:hover {
				.ag-icon-filter,
				.ag-icon-menu-alt {
					display: block;
				}
			}
		}
		.activity-header {
			.ag-icon-filter,
			.ag-icon-menu-alt {
				display: none;
			}
			:hover {
				.ag-icon-filter,
				.ag-icon-menu-alt {
					display: none;
				}
			}
		}
	}
}

.actionCell {
	width: 100%;

	div {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0px 30px;
		width: 100%;
		height: 100%;

		.action-button {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

.for-algin-coeff-table-width {
	.ag-theme-quartz {
		.right-align-headerName {
			.ag-cell-label-container {
				flex-direction: row;
			}
			.ag-header-cell-label {
				flex-direction: row-reverse;
			}
		}
	}
}

.coeff-table-grid .ag-theme-quartz .headerRightAlign {
	.ag-cell-label-container {
		flex-direction: row !important;
	}

	.ag-header-cell-label {
		flex-direction: row-reverse !important;
	}
}
//ag grid for logs screen custom css
.ag-column-drop-wrapper {
	display: none;
}
.activity-header {
	.ag-header-cell-label {
		justify-content: center !important;
	}
}
//ends here
// coeffViewList
.ag-theme-quartz {
	&.coeffViewList {
		.ag-row {
			border: 0px solid gray;

			&.ag-row-position-absolute {
				top: 10px;
			}
		}

		.ag-cell {
			border: 1px solid #eaeaea;
			padding: 0px 12px;
			border-radius: 4px;
			height: 42px;
			margin-left: 12px;

			&.elementColl {
				min-width: calc(306px - 100px) !important;
				max-width: calc(306px - 12px) !important;
			}

			&.number-align-right {
				text-align: center;
				width: calc(190px - 12px) !important;
			}

			&.Csfid-coll {
				width: calc(190px - 12px) !important;
			}

			&.CsfDescription-coll {
				width: calc(190px - 12px) !important;
			}

			&.InformationSource-coll {
				width: calc(240px - 12px) !important;
			}
		}

		.ag-ltr .ag-header-select-all,
		.ag-selection-checkbox {
			display: none;
		}
	}

	.ag-checkbox-input-wrapper.ag-indeterminate::after {
		color: rgba(56, 62, 59, 1) !important;
	}

	div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
		background-color: #222 !important;
	}

	.image-text {
		display: flex;

		span {
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			overflow: hidden;
			max-width: 13em;
			line-height: 1.1;
			padding: 10px 0px 10px;
		}
	}

	.activity-div-col {
		display: flex;
		align-items: center;
		justify-content: center;

		.activity-div {
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			overflow: hidden;
			max-width: 100px;
		}
	}
}
// roles
.forNamePadding {
	.ag-header-cell-label {
		padding-left: 30px;
	}
}

input:internal-autofill-selected {
	background-color: #fff !important;
}
.go3489369143:focus-within {
	outline: 0px solid #039855 !important;
}

.go1754112896 {
	bottom: 1px !important;
}

.tooltip-wrapper {
	font-size: 12px;

	.bubble-tooltip.header-tooltip {
		background-color: #f3f3f3;
		padding: 0px;

		.pd {
			padding: 14px 10px;
		}
	}

	.bubble-tooltip {
		padding: 5px 10px;
		background-color: #fff;
	}

	b {
		padding-left: 5px;
	}
}

.bubble-chart-container .apexcharts-series-markers.apexcharts-series-bubble {
	cursor: pointer !important;
}

.apexcharts-series.apexcharts-pie-series.unselected {
	opacity: 0.2;
}

.apexcharts-series.apexcharts-pie-series.selected {
	opacity: 1;
}

.unselected {
	opacity: 0.3;
}

.selected {
	opacity: 1;
}

.donut-chart-col {
	.apexcharts-text.apexcharts-pie-label {
		filter: none !important;
		font-weight: 500;
	}
}
.graph-common-css {
	.apexcharts-menu-icon {
		transform: scale(0.9);
	}
	.apexcharts-zoomin-icon,
	.apexcharts-zoomout-icon {
		transform: scale(0.75);
	}
	.apexcharts-zoom-icon {
		transform: scale(0.9);
	}
	.apexcharts-menu-icon,
	.apexcharts-reset-icon {
		margin-left: 3px;
	}
	.apexcharts-zoomout-icon {
		margin: 0px 3px 0px 3px;
	}
}
.full-view-chart-only {
	.apexcharts-canvas {
		height: 400px !important;
	}
	.apexcharts-title-text {
		font-size: 18px;
		font-weight: 600;
		line-height: 36px;
	}
	.apexcharts-text {
		&.apexcharts-yaxis-title-text,
		&.apexcharts-xaxis-title-text {
			font-size: 12px;
			font-weight: 400;
		}
	}
	.apexcharts-legend-marker {
		height: 12px;
		width: 12px;
	}
	.apexcharts-legend-text {
		font-size: 14px;
		font-weight: 400;
		line-height: 24px;
		color: #404040;
	}
	.apexcharts-legend-series {
		display: flex;
		align-items: center;
		justify-content: left;
	}

	&.last-chart {
		.apexcharts-canvas {
			height: 440px !important;
		}
	}
	&.last-chart2 {
		.apexcharts-canvas {
			height: 420px !important;
		}
	}
	.bubble-chart-container {
		.apexcharts-canvas {
			height: 385px !important;
		}
	}
	.fullView .apexcharts-canvas {
		height: calc(100vh - 18vh) !important;
	}
	.fullView.chart-four .apexcharts-canvas {
		height: calc(100vh - 16vh) !important;
	}

	.fullView {
		.tooltip-wrapper {
			.bubble-tooltip.header-tooltip {
				font-size: 14px;
			}
		}
		.apexcharts-title-text {
			font-size: 24px;
			font-weight: 700;
			line-height: 30px;
		}
		.apexcharts-text {
			&.apexcharts-yaxis-title-text,
			&.apexcharts-xaxis-title-text {
				font-size: 14px;
			}
		}
		.apexcharts-legend-marker {
			height: 14px !important;
			width: 18px !important;
			// top: -1px !important;
		}
		.apexcharts-legend-text {
			font-size: 15px !important;
			font-weight: 500 !important;
			line-height: 13px !important;
		}
	}
}
.apexcharts-xaxis-label.apexcharts-xaxis-labelesss {
	white-space: normal;
	word-wrap: break-word;
	max-width: 100px;
}

.fullScreenView {
	position: absolute;
	top: 23px;

	&.single-icon {
		right: 3.81em;
	}
	&.multi-icon {
		right: 11.7em;
	}
}
.closeFullScreen {
	position: absolute;
	top: 38px;
	&.single-icon {
		right: 4.86em;
	}
	&.multi-icon {
		right: 12em;
		&.second {
			top: 36px;
		}
		&.third {
			top: 35px;
		}
		&.fourth {
			right: 12.8em;
			top: 37px;
		}
		&.last {
			right: 12.8em;
			top: 37px;
		}
	}
}
.coeff-table-grid.roles-grid {
	.ag-row {
		box-shadow: 0px 0px 0px 0px #cad1d2;
		.grid-delete-icon {
			visibility: hidden;
			position: relative;
			&::before {
				content: "";
				position: absolute;
				background: transparent;
				width: 52px;
				height: 81%;
				border-radius: 88px;
				left: 0;
				transform: translate(14px, 1px);
			}
			&:hover {
				&::before {
					background: #e7494914;
				}
			}
		}
		&.ag-row-hover {
			background-color: #fff;
			box-shadow: 0px 2px 2px 2px #cad1d2;
			z-index: 2;
			&::before {
				background-color: transparent;
			}
			.grid-delete-icon {
				visibility: visible;
			}
		}
		&.ag-row-selected {
			background-color: #fff;
			box-shadow: 0px 2px 2px 2px #cad1d2;
			&::before {
				background-color: transparent;
			}
			.grid-delete-icon {
				visibility: visible;
			}
		}
	}
}
.coeff-table-grid.users-grid {
	.ag-theme-quartz {
		.image-text span {
			padding: 0px 0px 0px 0px;
		}
		.ag-cell {
			padding-top: 5px !important;
		}
		.activity-div-col {
			justify-content: left !important;
		}
	}
	.ag-row {
		box-shadow: 0px 0px 0px 0px #cad1d2;
		.grid-delete-icon {
			visibility: hidden;
			position: relative;
			&::before {
				content: "";
				position: absolute;
				background: transparent;
				width: 52px;
				height: 81%;
				border-radius: 88px;
				left: 0;
				transform: translate(14px, 1px);
			}
			&:hover {
				&::before {
					background: #e7494914;
				}
			}
		}
		&.ag-row-hover {
			background-color: #fff;
			box-shadow: 0px 2px 2px 2px #cad1d2;
			z-index: 2;
			&::before {
				background-color: transparent;
			}
			.grid-delete-icon {
				visibility: visible;
			}
		}
		&.ag-row-selected {
			background-color: #fff;
			box-shadow: 0px 2px 2px 2px #cad1d2;
			&::before {
				background-color: transparent;
			}
			.grid-delete-icon {
				visibility: visible;
			}
		}
	}
}
.cell-span {
	font-size: 14px !important;
	color: #808080 !important;
	font-style: italic;
	text-align: center !important;
	background-color: rgb(59 130 246 / 0.1) !important;
}
.coeff-table-grid .ag-theme-quartz.roles-details-member {
	.ag-cell {
		display: flex;
		align-items: center;
		justify-content: left;
	}
	.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
		border: 0px solid gray !important;
	}
	.slid-button-roles-members {
		display: flex;
		align-items: self-start;
		justify-content: left;
		.switch {
			position: relative;
			display: inline-block;
			width: 36px;
			height: 20px;
		}

		.switch input {
			opacity: 0;
			width: 0;
			height: 0;
		}

		.slider {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: #f3f3f3;
			-webkit-transition: 0.4s;
			transition: 0.4s;
		}

		.slider:before {
			position: absolute;
			content: "";
			height: 16px;
			width: 16px;
			left: 2px;
			bottom: 1px;
			background-color: #5a5a5a;
			border: 1px solid #ffffff;
			box-shadow: 0px 2px 4px 0px #00000040 inset;
			-webkit-transition: 0.4s;
			transition: 0.4s;
		}

		input:checked + .slider {
			background-color: #fff;
		}

		input:focus + .slider {
			box-shadow: 0 0 1px #2196f3;
		}

		input:checked + .slider:before {
			height: 24px;
			width: 24px;
			left: 0px;
			bottom: -2px;
			-webkit-transform: translateX(26px);
			-ms-transform: translateX(26px);
			transform: translateX(15px);
			border: 2px solid #ffffff;
			background-color: #d0aa45;
			box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
		}

		.slider.round {
			border-radius: 34px;
			border: 1px solid #afafaf;
		}

		.slider.round:before {
			border-radius: 50%;
		}

		input:checked + .slider.round {
			border: 1px solid #d0aa45;
		}
	}
}
.ag-header-cell.priority-center {
	.ag-header-cell-label {
		justify-content: center;
	}
	.ag-icon-small-down::after {
		z-index: 3;
		width: 50px;
	}
}
.ag-theme-quartz .ag-picker-field-wrapper {
	width: calc(100% - 15px) !important;
	position: absolute !important;
	background: #fff;
}
.coeff-table-grid.Scoredtails-modalGrid {
	.ag-header-cell:first-child,
	.ag-cell:first-child {
		padding-left: 24px;
	}
	.ag-header-cell:last-child,
	.ag-cell:last-child {
		padding-right: 24px;
	}
	.ag-theme-quartz {
		.ag-group-expanded,
		.ag-group-contracted {
			.ag-icon:hover {
				box-shadow: none !important;
				background-color: transparent !important;
			}
		}
		.right-align-headerName {
			.ag-cell-label-container {
				flex-direction: row;
			}

			.ag-header-cell-label {
				flex-direction: row-reverse;
			}
		}
	}
}
.applied-coefficient-icon {
	position: absolute;
	right: -12px;
	top: 8px;
	z-index: 3;
	cursor: pointer;
	width: 16px;
	height: 14px;
}
app-risk-element-info .showscrollFull.visible ~ .source-details {
	margin-top: 62px !important;
}

.effective-coeff-cell {
	&::before {
		content: " ";
		position: absolute;
		background-image: url(./assets/icons/change-value-coefficent.svg);
		right: 0;
		width: 24px;
		height: 24px;
		background-repeat: no-repeat;
	}
}
.ag-cell-value.repo-name-pointer {
	cursor: pointer !important;
	color: #448ee5;
	text-transform: capitalize;
}
.mercury-chart {
	.apexcharts-menu-icon {
		display: none;
	}
	.apexcharts-datalabel {
		fill: #404040 !important;
		font-weight: 500;
		line-height: 16px;
	}
}
.ag-cell-value.subAssetStyle {
	color: #448ee5;
	text-transform: capitalize;
	cursor: pointer;
}
.only-show-review-plan-com {
	display: none;
}
.mt-removed-reviewplan {
	app-risk-element-info {
		.showscrollFull {
			display: none;
		}
		.upperdiv {
			padding: 14px 18px 14px 18px;
			margin-top: 0;
			div {
				border-radius: 6px;
			}
			.only-show-details-com {
				display: none;
			}
			.only-show-review-plan-com {
				display: flex;
			}
			.mercury-chart.only-review-plan-chart {
				background: #f4f9ff80;
			}
		}
	}
}
.coeff-table-grid.new-grid-review-plan .ag-theme-quartz {
	.grid-review-plan-comment {
		background-image: url("./assets/icons/comment-icon-reviewplan.svg");
	}
	.ag-cell-value.ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-column-first {
		color: #448ee5;

		&::before {
			content: " ";
			position: absolute;
			right: 6px;
			height: 16px;
			width: 22px;
			background-image: url("assets/icons/info_filled.svg");
			background-position: center;
			background-repeat: no-repeat;
			background-size: 16px;
			top: 34%;
			opacity: 0;
		}
		&:hover {
			&::before {
				opacity: 1;
			}
		}
	}
	.ag-row-last.ag-row-focus.ag-row-selected {
		.ag-cell-value.ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-column-first {
			color: #448ee5;

			&::before {
				opacity: 1;
			}
		}
	}
	.ag-header-cell.ag-column-first.ag-header-span-height.ag-header-span-total.ag-header-cell-sortable.ag-focus-managed.color-regular-col,
	.ag-header-cell.ag-header-span-height.ag-header-span-total.ag-header-cell-sortable.priority-center.ag-focus-managed {
		background-color: #f2f3f4;
	}
	.ag-header-group-cell.ag-header-group-cell-with-group.ag-focus-managed {
		border-bottom: 1px solid #d9d9d9;
		&.color-regular-col {
			background-color: #fffbf0;
		}
		&.What-if-color-header {
			background-color: #f4f9ff;
		}
	}
	.ag-header-cell.ag-header-cell-sortable.ag-focus-managed {
		background-color: #fffbf0;
		&.child-header-class {
			background-color: #f4f9ff;
		}
	}
	.ag-cell-value.ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height:nth-child(2) {
		text-align: right;
	}
	.ag-cell-value.ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height:nth-child(3) {
		text-align: right;
		font-size: 14px;
		font-weight: 600;
	}
	.ag-cell.group-cell-text-right {
		text-align: right;
	}
	.ag-row-hover {
		background: #fbfbfb !important;
		border-bottom: 1px solid #f3f3f3 !important;
		background-image: none !important;
		box-shadow: 0px 2px 4px 2px rgba(202, 209, 210, 1);

		&.ag-row-selected::before {
			background-image: none !important;
			box-shadow: 0px 2px 4px 2px rgba(202, 209, 210, 1);
		}
	}
}

@media (max-width: 1600px) {
	.closeFullScreen.single-icon {
		top: 38.2px;
	}
	.closeFullScreen.multi-icon {
		top: 40px;
		&.second {
			top: 36px;
		}
		&.third {
			top: 36px;
		}
	}
}
@media (max-width: 1536px) {
	.full-view-chart-only .apexcharts-title-text {
		font-size: 16px;
	}
	.closeFullScreen.single-icon {
		top: 37.2px;
	}
	.closeFullScreen.multi-icon {
		top: 38px;
		&.second {
			top: 35px;
		}
		&.third {
			top: 35px;
		}
	}
}
@media (max-width: 1440px) {
	.full-view-chart-only .apexcharts-title-text {
		font-size: 14px;
	}
	.fullScreenView.multi-icon {
		top: 24px;
	}
	.closeFullScreen {
		right: 12.1em;
		top: 39px;
		&.third {
			top: 36px;
		}
	}
}

@media (max-width: 1366px) {
	.full-view-chart-only .apexcharts-title-text {
		font-size: 14px;
	}
	.closeFullScreen.single-icon {
		top: 34px;
		&.second-single {
			top: 32px;
		}
	}
	.closeFullScreen.multi-icon {
		top: 34px;
		&.second {
			top: 31px;
		}
		&.third {
			top: 31px;
		}
		&.fourth {
			top: 32px;
		}
		&.last {
			top: 32px;
		}
	}
}
@media (max-width: 1330px) {
	.mt-removed-reviewplan {
		.detail-avr .avr-type-list li > .type-div-what-if {
			padding: 0rem 1rem;
		}
	}
}
@media (max-width: 1280px) {
	.full-view-chart-only .apexcharts-title-text {
		font-size: 13px;
	}
	.closeFullScreen.single-icon {
		top: 33px;
		&.second-single {
			top: 30px;
		}
	}
	.closeFullScreen.multi-icon {
		top: 32px;
		&.second,
		&.third {
			top: 28px;
		}
		&.fourth,
		&.last {
			top: 30px;
		}
	}
}
@media (width: 1600px) and (height: 1050px) {
	.fullScreenView {
		top: 24px;
	}
	.closeFullScreen.single-icon {
		top: 43.5px;
	}
	.closeFullScreen.multi-icon {
		top: 45px;
		&.second,
		&.third {
			top: 42px;
		}
		&.fourth,
		&.last {
			top: 43px;
		}
	}
}
@media (width: 1536px) and (height: 670px) {
	.closeFullScreen.single-icon {
		top: 32.2px;
		&.second-single {
			top: 28.6px;
		}
	}
	.closeFullScreen.multi-icon {
		top: 31px;
		&.second,
		&.third {
			top: 26px;
		}
		&.fourth,
		&.last {
			top: 28px;
		}
	}
}
@media (width: 1366px) and (height: 650px) {
	.fullScreenView.multi-icon {
		top: 23px;
	}
	.closeFullScreen {
		top: 29px;
	}
	.closeFullScreen.single-icon {
		top: 32px;
		&.second-single {
			top: 28px;
		}
	}
	.closeFullScreen.multi-icon {
		top: 30px;
		&.second,
		&.third {
			top: 26px;
		}
		&.fourth,
		&.last {
			top: 27px;
		}
	}
}
@media (width: 1366px) and (height: 633px) {
	.fullScreenView.multi-icon {
		top: 23px;
	}
	.closeFullScreen {
		top: 28px;
	}
	.closeFullScreen.single-icon {
		top: 29px;
		&.second-single {
			top: 26px;
		}
	}
	.closeFullScreen.multi-icon {
		top: 28px;
		&.second,
		&.third {
			top: 24px;
		}
		&.fourth,
		&.last {
			top: 26px;
		}
	}
}
@media (width: 1366px) and (min-height: 500px) and (max-height: 620px) {
	.fullScreenView.multi-icon {
		top: 21px;
	}
	.closeFullScreen {
		top: 26px;
	}
	.closeFullScreen.single-icon {
		top: 29px;
		&.second-single {
			top: 24px;
		}
	}
	.closeFullScreen.multi-icon {
		top: 26px;
		&.second,
		&.third {
			top: 22px;
		}
		&.fourth,
		&.last {
			top: 24px;
		}
	}
}
@media (width: 1280px) and (height: 1024px) {
	.full-view-chart-only .apexcharts-title-text {
		font-size: 13px;
	}
	.closeFullScreen.single-icon {
		top: 33px;
		&.second-single {
			top: 42px;
		}
	}
	.closeFullScreen.multi-icon {
		top: 43px;
		&.second,
		&.third {
			top: 40px;
		}
		&.fourth,
		&.last {
			top: 42px;
		}
	}
}
.apexcharts-bar-series .apexcharts-series-markers,
.apexcharts-bar-series .apexcharts-bar-area,
.apexcharts-series-markers .apexcharts-marker,
.apexcharts-series path {
	cursor: pointer !important;
}
