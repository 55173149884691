.p-fileupload-choose.p-fileupload-choose-selected upload-btn {
	background-color: #fff !important;
}

// tab-button- width- next-prv
.btn-next-prv-container {
	width: 100%;
	background-color: #fff;
}

.p-tabmenu-nav-content {
	width: calc(100% - 40px);
	margin: 0 auto;
	.p-tabmenu-nav {
		position: relative;
		&::after {
			content: " ";
			position: absolute;
			bottom: 2px;
			width: 100%;
			height: 1px;
			background-color: rgba(175, 175, 175, 0.5);
			left: 0;
		}
	}
	.p-tabmenu-nav:first-child {
		&::after {
			left: 12px;
		}
	}
}
.entitlement-tab {
	.p-tabmenu-nav-content .p-tabmenu-nav::after {
		width: calc(100% - 24px);
	}
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	color: rgba(90, 90, 90, 1);
	border: solid rgb(217, 217, 217);
	border-width: 0 0 1px 0;
	position: relative;
	padding: 1rem 1.25rem;

	&::after {
		content: " ";
		position: absolute;
		bottom: 0;
		width: calc(100% - 24px);
		height: 5px;
		background-color: rgba(208, 170, 69, 1);
		opacity: 0;
		left: 12px;
	}
	&::before {
		content: " ";
		position: absolute;
		bottom: 3px;
		width: 100%;
		height: 1px;
		background-color: rgba(175, 175, 175, 0.5);
		left: 0;
		z-index: 1;
	}
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:first-child {
	.p-menuitem-link {
		&::before {
			left: 12px;
		}
	}
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
	background: #ffffff;
	border-color: rgb(217, 217, 217);
	color: rgba(27, 28, 31, 1);
	font-weight: 600;

	&::after {
		opacity: 1;
	}
}

.p-tabmenu .p-tabmenu-nav-btn.p-link {
	background-image: linear-gradient(
		90deg,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.7) 20.63%,
		rgba(255, 255, 255, 0.7) 37.81%,
		#ffffff 47.09%,
		#ffffff 68.75%
	);
	color: rgba(27, 28, 31, 1);
	width: 60px;
	height: 42px;
	box-shadow: none;
	border-radius: 0;
	&.p-tabmenu-nav-next {
		padding: 5px 16px 5px 40px;
		right: 15px;
	}
	&.p-tabmenu-nav-prev {
		left: -3px;
		background-image: linear-gradient(
			242deg,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 0.7) 20.63%,
			rgba(255, 255, 255, 0.7) 37.81%,
			#ffffff 47.09%,
			#ffffff 68.75%
		);
	}
}

.p-inputwrapper.input-box {
	.p-dropdown {
		border: 1px solid rgba(217, 217, 217, 1);
		border-radius: 4px;
		outline: none;
		background: none;
		height: 40px;
		min-width: 280px;
		position: relative;
		display: flex;
		align-items: center;

		.p-inputtext {
			font-size: 14px;
			line-height: 18px;
			font-weight: 400;
			color: rgba(128, 128, 128, 1);
			padding: 0 16px;
			transition: none;
		}

		.p-dropdown-label:focus {
			box-shadow: none;
		}

		.p-dropdown-trigger {
			background: transparent;
			width: 30px;
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;

			.p-icon-wrapper {
				svg {
					width: 24px;
					height: 24px;
					background-image: url("/assets/icons/arrow-drop-brown.svg");
					background-position: center;
					background-repeat: no-repeat;
					background-size: 24px;

					path {
						display: none;
					}
				}
			}
		}
	}

	.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
		margin: 0;
		padding: 10px 16px;
		border: 0 none;
		color: rgba(128, 128, 128, 1);
		background: transparent;
		transition: none;
		border-radius: 0;
		font-size: 14px;
		line-height: 18px;
		font-weight: 400;
	}

	.p-dropdown:not(.p-disabled).p-focus {
		box-shadow: none;
	}

	.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
		color: #000;
		background: #fff;
	}

	.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
		background: rgb(251, 251, 251);
	}
}

.select-level-div {
	.p-inputwrapper.input-box .p-dropdown {
		height: 44px;
		border: 1px solid rgba(175, 175, 175, 1);
	}

	.p-inputwrapper.input-box .p-dropdown .p-inputtext {
		font-size: 15px;
		color: rgba(82, 81, 81, 1);
	}
}

.input-file-upload {
	.p-button {
		border: 1px solid rgba(217, 217, 217, 1);
		border-radius: 4px;
		outline: none;
		background: none;
		height: 40px;
		min-width: 400px;
		position: relative;
		color: rgba(128, 128, 128, 1);
		text-align: left;
		flex-direction: row-reverse;
		padding: 0 10px 0px 16px;

		.p-button-icon-left {
			margin-right: 0rem;
		}

		&:focus {
			box-shadow: none;
		}
	}

	.p-icon-wrapper {
		svg {
			width: 24px;
			height: 24px;
			background-image: url("/assets/icons/upload.svg");
			background-position: center;
			background-repeat: no-repeat;
			background-size: 24px;

			path {
				display: none;
			}
		}
	}

	.p-button-label {
		font-weight: 400;
		transition: none;
		border-radius: 0;
		font-size: 14px;
		line-height: 18px;
	}
}

.p-inputtext:enabled:focus {
	outline: 0 none;
	box-shadow: none;
	border-color: #eaeaea;
}

.addeHoverClass .risk-input-list {
	.p-inputtext:enabled:focus {
		border-color: #afafaf;
	}
}

.risk-input-wrap {
	.p-component:disabled {
		opacity: 100%;
	}
}

#logout-button {
	.p-menuitem-content {
		padding: 0px 12px;

		.p-menuitem-link {
			position: relative;

			&::before {
				content: " ";
				position: absolute;
				left: 0;
				height: 24px;
				width: 24px;
				background: url("../assets/leftnav-icons/logout.svg");
				background-repeat: no-repeat;
				background-position: center;
				filter: invert(0.8);
			}

			.p-menuitem-text {
				padding-left: 10px;
				font-size: 16px;
				font-weight: 500;
				line-height: 16px;
				color: rgb(36, 36, 36);
			}
		}
	}
}

#settings-icon {
	.p-menuitem-content {
		padding: 0px 12px;

		.p-menuitem-link {
			position: relative;

			&::before {
				content: " ";
				position: absolute;
				left: 0;
				height: 24px;
				width: 24px;
				background: url("../assets/icons/settings.svg");
				background-repeat: no-repeat;
				background-position: center;
			}

			.p-menuitem-text {
				padding-left: 10px;
				font-size: 16px;
				font-weight: 500;
				line-height: 16px;
				color: rgb(36, 36, 36);
			}
		}
	}
}

.poppu-menu {
	position: absolute;
	top: 18px;
	left: 10px;
	.p-component {
		width: 7rem;
	}
	.p-menu .p-submenu-header {
		padding: 0rem 1.25rem;
	}

	.p-component.p-menu-overlay {
		top: 42px !important;
	}

	.p-menuitem:hover {
		background-color: #f3f3f3;
	}

	.p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
		background-color: transparent;
	}
}

.score-more-icon-pop-up {
	.p-menu {
		min-width: 3rem !important;
		width: auto !important;
		top: 116px !important;

		.p-menuitem:hover {
			background-color: #f3f3f3;
		}

		.p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
			background-color: transparent;
		}
	}
	&.for-flagged {
		position: relative;
		// top: 13px;
		p-menu.p-element {
			position: absolute;
			right: 0;
		}
		.p-menu {
			top: 13px !important;
			left: calc(100% - 117px) !important;
			width: 8rem !important;
			box-shadow: -2px 2px 8px 0px #00000040;
			position: relative;
			&::after {
				content: "";
				position: absolute;
				top: -12%;
				left: 78%;
				border-width: 8px;
				border-style: solid;
				border-color: transparent transparent #fff transparent;
			}
		}
		.p-menu-list.p-reset {
			.p-element.p-menuitem {
				padding: 0px 12px 5px 12px;
				&:last-child {
					padding: 0px 12px 0px 12px;
				}
			}
		}
	}

	#download {
		.p-menuitem-content {
			padding: 0px 12px;

			.p-menuitem-link {
				position: relative;

				&::before {
					content: " ";
					position: absolute;
					left: 0;
					height: 26px;
					width: 26px;
					background: url("../assets/icons/download.svg");
					background-repeat: no-repeat;
					background-position: center;
					background-size: 26px;
				}

				.p-menuitem-text {
					padding-left: 10px;
					font-size: 16px;
					font-weight: 500;
					line-height: 16px;
					color: rgb(36, 36, 36);
				}
			}
		}
	}
	#edit {
		.p-menuitem-content {
			padding: 0px 12px;

			.p-menuitem-link {
				position: relative;

				&::before {
					content: " ";
					position: absolute;
					left: 0;
					height: 24px;
					width: 26px;
					background: url("../assets/icons/edit.svg");
					background-repeat: no-repeat;
					background-position: center;
					background-size: 24px;
				}

				.p-menuitem-text {
					padding-left: 10px;
					font-size: 16px;
					font-weight: 500;
					line-height: 16px;
					color: rgb(36, 36, 36);
				}
			}
		}
	}
}

.element-risk {
	.p-dropdown {
		background: transparent;
		border: 0px solid #d1d5db;
		transition: none;
		border-radius: 0px;
	}

	.p-dropdown-label {
		font-size: 18px;
		font-weight: 500;
		line-height: 32px;
		color: #d0aa45;
	}

	.p-inputtext {
		padding: 10px 2px;
	}

	.p-dropdown-label:focus {
		box-shadow: none;
	}

	.p-dropdown-trigger {
		background: transparent;
		width: 30px;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;

		.p-icon-wrapper {
			svg {
				width: 24px;
				height: 24px;
				background-image: url("/assets/icons/arrow-drop.svg");
				background-position: center;
				background-repeat: no-repeat;
				background-size: 30px;

				path {
					display: none;
				}
			}
		}
	}

	.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
		margin: 0;
		padding: 10px 16px;
		border: 0 none;
		color: rgba(128, 128, 128, 1);
		background: transparent;
		transition: none;
		border-radius: 0;
		font-size: 16px;
		line-height: 18px;
		font-weight: 400;
	}

	.p-dropdown:not(.p-disabled).p-focus {
		box-shadow: none;
	}

	.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
		color: #d0aa45;
		background: #fff;
	}

	.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
		background: rgb(251, 251, 251);
	}
}

.product-detail-tab {
	.p-tabview .p-tabview-nav {
		background: #ffffff;
		border: 1px solid #afafaf;
		border-width: 0 0 2px 0;
	}

	.p-tabview .p-tabview-nav li .p-tabview-nav-link {
		border: solid #e5e7eb;
		border-width: 0 0 0px 0;
		border-color: #afafaf;
		background: #ffffff;
		color: #6b7280;
		padding: 0.5rem 1.25rem 0.75rem;
		font-weight: 500;
		border-top-right-radius: 6px;
		border-top-left-radius: 6px;
		transition: box-shadow 0.2s;
		margin: 0 0 -2px 0;
		font-size: 15px;
		line-height: 24;
	}

	.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
		background: #ffffff;
		border-width: 0 0 4px 0;
		border-color: #d0aa45;
		color: #242424;
	}

	.p-tabview .p-tabview-panels {
		background: #ffffff;
		padding: 15px 0px 0px 4px;
		border: 0 none;
		color: #4b5563;
		border-bottom-right-radius: 6px;
		border-bottom-left-radius: 6px;
	}
}

.p-tooltip-text {
	background-color: #1b1c1f;
	color: #fff;
}

.p-tooltip-arrow {
	border-right-color: #1b1c1f;
}

.p-radiobutton .p-radiobutton-box {
	border: 1px solid #afafaf;
	background-color: transparent;
	width: 18px;
	height: 18px;

	&.p-highlight {
		border: 1px solid #383e3b;
		background-color: transparent;
	}

	.p-radiobutton-icon {
		background-color: #383e3b;
		width: 10px;
		height: 10px;
	}
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
	box-shadow: none;
}

.p-button:focus {
	box-shadow: none;
}

// percentile pop-up
.common-css-for-perce-quart-pop-up {
	.p-sidebar-right {
		top: 116px;
		right: 25px;
		width: 384px;
		height: calc(100vh - 135px);
		border-radius: 4px;
		border: 1px solid #edecea;
		box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
	}

	.p-sidebar-header {
		display: none;
	}
}

.p-skeleton {
	border-radius: 4px;
}

.risk-coeff-skeleton {
	.p-skeleton.p-component {
		height: 41px !important;
	}
}

.avg-data-name-skeleton {
	.p-skeleton.p-component {
		height: 40px !important;
		border-radius: 2px !important;
	}
}

.avr-type-list-skeleton {
	.p-skeleton.p-component {
		height: 20px !important;
		border-radius: 2px !important;
	}
}
.owner-chart-skeleton {
	.p-skeleton.p-component {
		height: 100px !important;
		border-radius: 2px !important;
	}
}

.show-groups-list {
	.avr-type-list-skeleton {
		.p-skeleton.p-component {
			height: 30px !important;
		}
	}
}

.view-agenda-input-skeleton {
	.p-skeleton.p-component {
		height: 130px !important;
	}
}

.product-detail-grid .risk-score-skeleton {
	.p-skeleton.p-component {
		height: 30px !important;
	}
}

.product-detail-grid .risk-coeff-skeleton:first-child {
	.p-element {
		margin-bottom: 0px;
	}

	.p-skeleton.p-component {
		margin: 15px 0px;
	}
}

.bar-skeleton {
	.p-skeleton {
		height: 35px !important;
	}
}

.round-skeleton {
	.p-skeleton {
		width: 1.3rem !important;
		height: 1.3rem !important;
		border-radius: 50% !important;
	}
}

.tab-skeleton-show {
	.p-skeleton {
		height: 2rem !important;
	}
}

@media screen and (max-width: 1535px) {
	.product-detail-tab .p-tabview .p-tabview-panels {
		padding: 10px 0px 0px 4px;
	}
}

p-dialog {
	padding: none !important;
}
//modal css

#link-checkbox {
	accent-color: #ae8a2b;
	opacity: 0.8;
}
.upload_file {
	position: relative;
}
.upload_file:after {
	content: "";
	background-image: url("/assets/icons/upload.svg");
	background-repeat: no-repeat;
	background-size: 22px;
	right: 15px;
	position: absolute;
	height: 22px;
	width: 22px;
}

.entitlement-confirm-modal-dropdown {
	width: 100%;
	padding: 0px 30px 0px 10px;

	.p-element.p-inputwrapper {
		width: 100%;
		display: block;
	}
	.p-dropdown.p-component.p-inputwrapper {
		width: 100%;
	}
	.p-dropdown {
		border: 1px solid #5a5a5a;
		border-radius: 4px;
		outline: none;
		background: none;
		height: 32px;
		width: 100%;
		position: relative;
		display: flex;
		align-items: center;

		.p-inputtext {
			font-size: 14px;
			line-height: 18px;
			font-weight: 400;
			color: #1b1c1f;
			padding: 0 16px;
			transition: none;
		}

		.p-dropdown-label:focus {
			box-shadow: none;
		}

		.p-dropdown-trigger {
			background: transparent;
			width: 45px;
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;

			.p-icon-wrapper {
				svg {
					width: 24px;
					height: 24px;
					background-image: url("/assets/icons/arrow-expand-dropdown.svg");
					background-position: center;
					background-repeat: no-repeat;
					background-size: 24px;

					path {
						display: none;
					}
				}
			}
		}
	}
	.p-inputwrapper-filled {
		.p-inputtext {
			color: #404040;
		}
	}
	.p-dropdown-panel {
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
		.p-dropdown-items-wrapper {
			max-height: 300px !important;
		}
	}

	blankicon.p-icon-wrapper {
		border: 2px solid rgba(175, 175, 175, 1);
		width: 18px;
		height: 18px;
		border-radius: 2px;
		margin-right: 12px;
	}
	checkicon.p-icon-wrapper {
		border: 2px solid rgba(56, 62, 59, 1);
		width: 18px;
		height: 18px;
		border-radius: 2px;
		margin-right: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			width: 12px;
			height: 12px;
		}
	}
	.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
		margin: 0;
		padding: 10px 16px;
		border: 0 none;
		color: rgba(90, 90, 90, 1);
		background: transparent;
		transition: none;
		border-radius: 0;
		font-size: 14px;
		line-height: 18px;
		font-weight: 400;
		display: flex;
		align-items: center;
		justify-content: left;
	}

	.p-dropdown:not(.p-disabled).p-focus {
		box-shadow: none;
	}

	.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
		color: rgba(27, 28, 31, 1);
		background: #fff;
	}

	.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
		background: rgb(251, 251, 251);
	}

	&.for-users-detaild {
		width: calc(100% - 62%);
		.p-disabled {
			border: 1px solid #d9d9d9;
			background: #f8f8f8;
			.p-inputtext {
				color: #5a5a5a;
			}
			.p-dropdown-trigger .p-icon-wrapper svg {
				opacity: 60%;
			}
		}
	}
	p-treeselect.p-element.p-inputwrapper {
		width: 100%;
		display: block;
		border: 1px solid rgba(90, 90, 90, 1);
		border-radius: 4px;
		&:focus {
			outline: none;
		}
		.p-treeselect.p-component.p-inputwrapper {
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: left;
		}
		.p-treeselect.p-treeselect-chip .p-treeselect-token {
			padding: 0px 4px !important;
			margin-right: 0.5rem;
			background: transparent;
			color: #404040;
			font-size: 15px !important;
			font-weight: 400;
			border-radius: 2px;
		}
		.p-tree {
			margin: 0;
			padding: 10px 4px;
			border: 0 none;
			color: rgba(90, 90, 90, 1);
			background: transparent;
			transition: none;
			border-radius: 0;
			font-size: 14px;
			line-height: 18px;
			font-weight: 400;
		}
		.p-treeselect-label.p-placeholder {
			color: #404040;
			font-size: 15px !important;
			font-weight: 400;
		}
		.p-treeselect-trigger {
			background: transparent;
			width: 45px;
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;

			.p-icon-wrapper {
				svg {
					width: 24px;
					height: 24px;
					background-image: url("/assets/icons/arrow-expand-dropdown.svg");
					background-position: center;
					background-repeat: no-repeat;
					background-size: 24px;

					path {
						display: none;
					}
				}
			}
		}

		checkicon.p-icon-wrapper {
			border: 0px solid rgba(56, 62, 59, 1);
			width: 18px;
			height: 18px;
			border-radius: 2px;
			margin-right: 0px;
			display: flex;
			align-items: center;
			justify-content: center;
			svg {
				width: 12px;
				height: 12px;
				color: #404040 !important;
			}
		}
		.p-checkbox .p-checkbox-box {
			border: 2px solid #afafaf;
			background: transparent;
			width: 18px;
			height: 18px !important;
			border-radius: 2px;
			transition:
				background-color 0.2s,
				color 0.2s,
				border-color 0.2s,
				box-shadow 0.2s;
			outline-color: transparent;
			&.p-highlight {
				border: 2px solid rgba(56, 62, 59, 1);
			}
		}
		.p-treenode-label {
			font-size: 15px;
			font-weight: 400;
			color: #5a5a5a;
		}
		.p-treenode-content.p-treenode-selectable {
			.p-tree-toggler {
				chevronrighticon,
				chevrondownicon {
					svg {
						width: 14px;
						height: 14px;
					}
				}
			}
		}
		.p-treenode-selectable.p-highlight {
			background: rgb(251, 251, 251);
			.p-treenode-label {
				font-size: 15px;
				font-weight: 400;
				color: #5a5a5a;
			}
		}
		.p-treenode:focus {
			outline: none;
			box-shadow: none !important;
		}

		.p-tree .p-tree-container .p-treenode:focus > .p-treenode-content {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: inset 0 0 0 0.15rem transparent;
		}
		.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler {
			color: #404040;
		}
	}
}

.contact-dropdown {
	width: 85px;
	padding: 0px 8px 0px 4px;
	.p-element.p-inputwrapper {
		width: 100%;
		display: block;
	}
	.p-dropdown.p-component.p-inputwrapper {
		width: 100%;
	}
	.p-dropdown {
		border-radius: 4px;
		outline: none;
		background: none;
		height: 40px;
		width: 100%;
		position: relative;
		display: flex;
		align-items: center;

		.p-inputtext {
			font-size: 14px;
			line-height: 18px;
			font-weight: 500;
			color: #1b1c1f;
			padding: 0px 2px 0px 10px;
			transition: none;
		}

		.p-dropdown-label:focus {
			box-shadow: none;
		}

		.p-dropdown-trigger {
			background: transparent;
			width: 24px;
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;

			.p-icon-wrapper {
				svg {
					width: 24px;
					height: 24px;
					background-image: url("/assets/icons/arrow-drop.svg");
					background-position: center;
					background-repeat: no-repeat;
					background-size: 24px;
					filter: invert(6) brightness(0.5);

					path {
						display: none;
					}
				}
			}
		}
	}
	.p-inputwrapper-filled {
		.p-inputtext {
			color: #404040;
		}
	}
	.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
		margin: 0;
		padding: 10px 16px;
		border: 0 none;
		color: rgba(128, 128, 128, 1);
		background: transparent;
		transition: none;
		border-radius: 0;
		font-size: 14px;
		line-height: 18px;
		font-weight: 400;
	}

	.p-dropdown:not(.p-disabled).p-focus {
		box-shadow: none;
	}

	.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
		color: #000;
		background: #fff;
	}

	.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
		background: rgb(251, 251, 251);
	}
}
.multiselectDropdownUser {
	p.component {
		font-size: 15px !important;
	}
	.p-multiselect-header {
		display: none;
	}
	.p-multiselect.p-inputwrapper {
		width: 100%;
		height: 32px;
		padding: 0 4px;
	}

	.p-multiselect .p-multiselect-label {
		padding: 3.2px 12px;
		font-size: 15px;
	}
	.p-element.p-multiselect-label-container {
		height: 32px;
	}
	.p-multiselect-trigger {
		height: 30px;
		width: 30px;
		.p-icon-wrapper svg path {
			display: none;
		}
		.p-icon-wrapper svg {
			width: 24px;
			height: 24px;
			background-image: url("/assets/icons/arrow-drop.svg");
			background-position: center;
			background-repeat: no-repeat;
			background-size: 24px;
			filter: invert(6) brightness(0.5);
		}
	}
	.p-checkbox .p-checkbox-box {
		border: 2px solid#C0C0C0 !important;
		width: 18px !important;
		height: 18px !important;
		border-radius: 2px !important;
	}
	.p-checkbox .p-checkbox-box.p-highlight {
		border: 2px solid #5a5a5a !important;
		background-color: #fff !important;
	}
	.p-multiselect-item {
		font-size: 15px !important;
		line-height: 24px !important;
		color: #5a5a5a !important;
	}
	.p-checkbox {
		width: 18px !important;
		height: 18px !important;
	}
	.p-checkbox .p-checkbox-box .p-checkbox-icon {
		color: #5a5a5a !important;
	}
	.p-checkbox .p-checkbox-box .p-icon {
		width: 12px !important;
		height: 12px !important;
	}
	.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
		margin-right: 12.8px !important;
	}
}

#editIcons {
	.p-menuitem-content {
		padding: 0px 12px;

		.p-menuitem-link {
			position: relative;

			&::before {
				content: " ";
				position: absolute;
				left: 0;
				height: 24px;
				width: 24px;
				background-image: url("/assets/icons/edit.svg");
				background-repeat: no-repeat;
				background-position: center;
				background-size: 24px;
			}

			.p-menuitem-text {
				padding-left: 10px;
				font-size: 16px;
				font-weight: 500;
				line-height: 16px;
				color: rgb(36, 36, 36);
			}
		}
	}
}
.editformWrap {
	.contact-dropdown .p-dropdown {
		height: 30px;
	}
}

// toast
.success-toast-wrap {
	.p-toast .p-toast-message {
		box-shadow: none;
		backdrop-filter: blur(0px);
	}
	.p-toast-message-content {
		display: flex;
		align-items: center;
		justify-content: left;
		background: linear-gradient(0deg, rgba(46, 133, 64, 0.05), rgba(46, 133, 64, 0.05));
		border: 1px solid #6ac874;
		border-radius: 8px;
		padding: 16px 24px 16px 16px;
		position: relative;
		button timesicon {
			position: fixed;
			top: 15px;
			right: 20px;
		}
	}
	.p-toast-message-icon {
		width: 56px;
		height: 56px;
		background: rgba(46, 133, 64, 0.1019607843);
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		.p-icon-wrapper {
			svg {
				width: 32px;
				height: 32px;
				background-image: url("/assets/icons/check_circle-toast.svg");
				background-position: center;
				background-repeat: no-repeat;
				background-size: 32px;
				path {
					display: none;
				}
			}
		}
	}
	.p-toast-message-text {
		.p-toast-summary {
			font-size: 15px;
			font-weight: 400;
			color: #383e3b;
		}
		.p-toast-detail {
			font-size: 14px;
			font-weight: 400;
			color: #5a5a5a;
			margin: 0rem 0 0 0;
		}
	}
}
.scoreDetailCheckbox {
	.p-checkbox {
		width: 16px !important;
		height: 16px !important;
		.p-checkbox-box {
			border-style: solid;
			border-width: 2px !important;
			border-color: #afafaf;
			width: 16px !important;
			height: 16px !important;
			border-radius: 2px !important;
			background-color: #fff;
			outline-color: transparent;
			box-shadow: none;

			.p-checkbox-icon {
				transition-duration: 0.2s;
				color: #000;
				font-size: 16px;
			}
			.p-icon {
				width: 10px !important;
				height: 10px !important;
			}
		}
		&.p-checkbox-checked {
			.p-checkbox-box {
				border-color: #383e3b;
			}
		}
	}
}
.form-list-details {
	.p-inputtext:enabled:focus {
		outline: 0 none;
		box-shadow: none;
		border-color: #d9d9d9;
	}

	.p-inputtext.p-inputtext-edit:enabled:focus {
		border-color: #5a5a5a;
	}
}
.ticketModalaccordian {
	overflow: auto;
	overflow-x: hidden;
	min-height: calc(100vh - 414px);
	max-height: calc(100vh - 282px);
	display: block;
	padding: 10px 2px 0px;
	.p-accordion-tab {
		margin-bottom: 18px;
		.p-toggleable-content {
			border-top: 1px solid #eaeaea;
		}
	}
	.p-accordion-header-link {
		justify-content: space-between !important;
		flex-direction: row-reverse;
		height: 56px;
		border: 1px solid #d9d9d9;
		background-color: #fbfbfb;
		border-bottom-right-radius: 6px;
		border-bottom-left-radius: 6px;
		box-shadow: none;
		.p-accordion-header-text {
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			color: #404040;
		}
		.p-element.p-icon-wrapper {
			transform: rotate(90deg);
			transition: all 0.2s ease;
			// border: 1px solid #808080;
			// border-radius: 50%;
		}
	}
	.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
		background-color: #fbfbfb;
		border: 1px solid #eaeaea;
		border-bottom: 0px solid transparent !important;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
		box-shadow: none;
		.p-element.p-icon-wrapper {
			transform: rotate(180deg);
			transition: all 0.2s ease;
		}
	}

	.p-accordion-content {
		background-color: #fff;
		border: 1px solid #eaeaea;
		border-top: 0px solid #eaeaea;
		padding: 0px 0px 0px;
		.p-inputtext:enabled:focus {
			outline: 0 none;
			box-shadow: none;
			border-color: #5a5a5a;
		}
		.readable-text.p-inputtext:enabled:focus {
			border-color: #eaeaea;
		}
	}
}
.effective-coeff-tooltip-show {
	.p-inputtext:hover ~ .save-after-edit-coefvalue-icon .effective-coeff-tooltip {
		display: flex;
		transition: display 0.4s ease;
	}
}
.p-dynamic-dialog {
	.p-dialog-content {
		padding: 0px 0px !important;
		border-radius: 6px !important;
	}
}
.search-box-div {
	.p-autocomplete-input {
		padding-left: 2px;
		min-width: 320px;
		max-width: 380px;
		::placeholder {
			color: #afafaf;
			font-size: 15px;
			font-weight: 400;
		}
	}
}

.p-inputwrapper.for-asset-select {
	width: 13.75rem;
	height: 32px;
	.p-multiselect.p-inputwrapper {
		width: 100%;
		background-color: #fbfbfb;
		border: 1px solid #5a5a5a;
		height: 32px;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		&:focus-visible {
			outline: none !important;
			box-shadow: none !important;
		}
		&.p-focus {
			outline: none !important;
			box-shadow: none !important;
		}
	}
	.p-multiselect-label {
		padding: 0px 16px !important;
		color: #464646;
		font-size: 15px;
		font-weight: 400;
	}
	.p-multiselect-trigger {
		background: transparent;
		width: 30px;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;

		.p-icon-wrapper {
			svg {
				width: 24px;
				height: 24px;
				background-image: url("/assets/icons/arrow-drop.svg");
				background-position: center;
				background-repeat: no-repeat;
				background-size: 24px;
				filter: invert(0.5) brightness(0.8);
				transform: rotate(-180deg);
				path {
					display: none;
				}
			}
		}
	}
	.p-multiselect-panel {
		border-radius: 0px 0px 4px 4px !important;
	}
	.p-checkbox.p-component {
		width: 20px;
		height: 15px;
		.p-checkbox-box {
			width: 18px;
			height: 18px;
			border: 2px solid rgb(175, 175, 175);
			background-color: transparent;
			border-radius: 2px;
			&.p-highlight {
				border: 2px solid rgb(56, 62, 59);
				background-color: transparent;
				checkicon.p-icon-wrapper {
					svg {
						width: 12px;
						height: 12px;
						color: rgb(56, 62, 59);
					}
				}
			}
		}
	}
	.p-element {
		&.p-multiselect-item {
			display: flex;
			align-items: center;
			justify-content: left;
			padding: 10px 16px;
			transition: none;
			span {
				font-size: 14px;
				line-height: 18px;
				font-weight: 400;
				color: rgb(27, 28, 31);
			}
			&.p-focus {
				span {
					color: rgb(90, 90, 90);
				}
			}
		}
	}
	.p-multiselect-header {
		display: none;
	}

	&.p-inputwrapper-filled {
		.p-multiselect.p-inputwrapper {
			background-color: #fff;
			border: 1px solid #d0aa45;
		}
		.p-multiselect-label {
			color: #d0aa45;
		}
		.p-multiselect-trigger {
			.p-icon-wrapper {
				svg {
					filter: none;
				}
			}
		}
	}
	.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus {
		background: rgb(251, 251, 251);
	}
}
