.icon {
	width: 24px;
	height: 24px;
	display: inline-flex;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
}

.btn-icon {
	width: 22px;
	height: 22px;
	display: inline-flex;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
}

.icon-api {
	background-image: url("/assets/icons/leaderboard.svg");
}

.icon-search {
	background-image: url("/assets/icons/search.svg");
}

.icon-download {
	background-image: url("/assets/icons/download.svg");
}

.icon-save {
	background-image: url("/assets/icons/bookmark_add.svg");
}

.app-icon-Frame {
	background-image: url("/assets/leftnav-icons/Frame.svg");
}

.app-icon-admin-panel {
	background-image: url("/assets/leftnav-icons/admin_panel_settings.svg");
}

.app-icon-logout {
	background-image: url("/assets/leftnav-icons/logout.svg");
	filter: invert(0.9) brightness(2);
}

.icon-source {
	background-image: url("/assets/icons/sort.svg");
}

.icon-edit {
	background-image: url("/assets/icons/edit.svg");
}

.icon-expand {
	background-image: url("/assets/icons/expand_collapse.svg");
}

.app-icon-dashboard {
	background-image: url("/assets/leftnav-icons/dashboard.svg");
}

.app-icon-avr {
	background-image: url("/assets/leftnav-icons/avr.svg");
}

.app-icon-addressable-risk {
	background-image: url("/assets/leftnav-icons/addressable_risk.svg");
}

.app-icon-dvr {
	background-image: url("/assets/leftnav-icons/dvr.svg");
}

.app-icon-risk-data {
	background-image: url("/assets/leftnav-icons/risk_data.svg");
}

.icon-grid {
	background-image: url("/assets/icons/table_view.svg");
}

.icon-add {
	background-image: url("/assets/icons/add.svg");
}

.icon-back {
	background-image: url("/assets/icons/back_state.svg");
}

.icon-angle {
	background-image: url("/assets/icons/arrow-chevron.svg");
}

.icon-info {
	background-image: url("/assets/icons/info.svg");
	width: 16px;
	height: 16px;
}

.icon-expand_circle {
	background-image: url("/assets/icons/expand_circle.svg");
}

.app-icon-avr-active {
	background-image: url("/assets/leftnav-icons/avr_active.svg");
}

.app-icon-dashboard-active {
	background-image: url("/assets/leftnav-icons/dashboard_active.svg");
}

.app-icon-dvr-active {
	background-image: url("/assets/leftnav-icons/dvr-active.svg");
}

.app-icon-risk-data-active {
	background-image: url("/assets/leftnav-icons/risk-data-active.svg");
}

.app-icon-account_circle_user {
	background-image: url("/assets/icons/account_circle_user.svg");
	width: 24px;
}

.app-drop-down {
	background-image: url("/assets/icons/arrow-drop.svg");
	width: 24px;
}

.icon-shorting {
	background-image: url("/assets/icons/sort.svg");
	width: 18px;
	height: 18px;
}

.app-icon-help {
	background-image: url("/assets/icons/help.svg");
}

.icon-address-name {
	background-image: url("/assets/icons/next_week.svg");
	width: 20px;
}

.pinned-icon {
	background-image: url("/assets/icons/push_pin.svg");
	width: 20px;
	height: 40px;
}

.icon-no-selected-table {
	background-image: url("/assets/icons/data_table.svg");
}

.icon-more-vert {
	background-image: url("/assets/icons/more_vert.svg");
}

.icon-add-data-table {
	background-image: url("/assets/icons/table.svg");
	width: 32px;
	height: 32px;
}

.icon-modal-close {
	background-image: url("/assets/icons/close.svg");
}

.icon-info-select {
	background-image: url("/assets/icons/info.svg");
	width: 24px;
	height: 24px;
}

.icon-expand {
	background-image: url("/assets/icons/expand_collapse.svg");
	width: 24px;
	height: 24px;
	display: inline-flex;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
}

.icon-collapse {
	background-image: url("/assets/icons/collapse.svg");
	width: 24px;
	height: 24px;
	display: inline-flex;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
}

.icon-arrow-down {
	background-image: url("/assets/icons/expand_more.svg");
	width: 15px;
	background-repeat: no-repeat;
}

.icon-view-list {
	background-image: url("/assets/icons/view_list.svg");
}

.icon-coeffcient-name {
	background-image: url("/assets/icons/asset-value-risk.svg");
}

.icon-fill-false {
	background-image: url("/assets/icons/info.svg");
	width: 16px;
	height: 16px;
	background-repeat: no-repeat;
	cursor: pointer;
}

.icon-fill-true {
	background-image: url("/assets/icons/info_filled.svg");
	width: 16px;
	height: 16px;
	background-repeat: no-repeat;
	cursor: pointer;
}

.icon-fill-visibility {
	background-image: url("/assets/icons/visibility_on.svg");
	width: 20px;
	height: 20px;
	background-repeat: no-repeat;
	cursor: pointer;
}

.icon-delete {
	background-image: url("/assets/icons/delete.svg");
	width: 20px;
	height: 20px;
	background-repeat: no-repeat;
	cursor: pointer;
}

.icon-download {
	background-image: url("/assets/icons/download.svg");
	width: 22px;
	height: 22px;
	background-repeat: no-repeat;
	cursor: pointer;
}

.icon-label_important {
	background-image: url("/assets/icons/label_important.svg");
	width: 32px;
	height: 32px;
}
